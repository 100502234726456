import React, { useContext } from "react"
import { CompanyInfoContext } from '../../../App'
import Constants from '../../../constants'
import './gameCategoryView.css'
import { Box, Button, Skeleton, Stack, Tab, Tabs } from '@mui/material'
import Utils from "../../../utils/utils"
import { useTemplateVariables } from "../../../utils/templateUtils"
import ButtonSelectable from "../../button/buttonSelectable"
import useScreenType from "../../../hooks/useScreenType"
import { useTranslation } from "react-i18next"

function GameCategoryView({ templateDesign = 1, gameCategories, companyGames = [], companyGameSequence = [], isLoading = false, selectedKey, onSelected }) {
    //Contexts
    const screenType = useScreenType()
    const { companyInfo } = useContext(CompanyInfoContext)
    const template = useTemplateVariables()
    const { t } = useTranslation()

    let buttonWidth = '211.2px'
    let buttonHeight = '48px'
    let imageSize = screenType.isMobile ? '80px' : '80px'
    let imageMargin = '10px'
    let marginBottom = screenType.isMobile ? '5px' : '10px'
    let imageContainerSize = `calc(${imageSize} + 45px)`

    //Component Methods
    const getSkeletonView = () => {
        let shimmerColor = 'rgba(255, 255, 255, 0.2)'
        let skeletonArray = Array(6).fill('')

        const getSkeletonWidth = () => {
            if (template.design === Constants.templateDesignModern) {
                return buttonWidth
            }

            return imageSize
        }
        const getSkeletonHeight = () => {
            if (template.design === Constants.templateDesignModern) {
                return buttonHeight
            }

            return imageSize
        }

        return skeletonArray.map((e, index) => {
            return (
                <div key={`shimmer${index}`} style={{ margin: imageMargin, marginBottom: marginBottom }}>
                    <Skeleton animation="wave" variant='rounded' width={getSkeletonWidth()} height={getSkeletonHeight()} sx={{
                        bgcolor: shimmerColor,
                    }} />
                </div>
            )
        })
    }

    //Show loading skeleton if is loading
    if (isLoading) {
        return (
            <Stack className="gameCategoryList" direction={'row'} sx={{
                msOverflowStyle: '-ms-autohiding-scrollbar',
                scrollbarWidth: 'none',
                width: '100%',
                overflowX: 'scroll',
                justifyContent: template.design === Constants.templateDesignModern ? 'center' : null
            }}>
                {getSkeletonView()}
            </Stack>
        )
    } else {
        if ((gameCategories?.length ?? 0) > 0) {
            var gameCategoryImages = gameCategories.map((e) => {
                var imageUrl = ''
                var isDefaultImage = true
                let key = e.key
                let value = e.value
                let isCustom = e?.isCustom ?? false

                //First check if is custom
                if (isCustom) {
                    imageUrl = `${process.env.PUBLIC_URL}/images/game/others.png`

                    let image = e?.image ?? ''
                    if (image.length > 0) {
                        imageUrl = Utils.shared.getCustomCategoryImage(companyInfo, image)
                        isDefaultImage = false
                    }
                } else {
                    switch (key) {
                        case 1:
                            imageUrl = `${process.env.PUBLIC_URL}/images/game/live_casino.png`

                            let gameCategoryCasinoImage = companyInfo.company.gameCategoryCasinoImage ?? ''
                            if (gameCategoryCasinoImage.length > 0) {
                                imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, gameCategoryCasinoImage, companyInfo.company._id)
                                isDefaultImage = false
                            }

                            break
                        case 2:
                            imageUrl = `${process.env.PUBLIC_URL}/images/game/slot.png`

                            let gameCategorySlotImage = companyInfo.company.gameCategorySlotImage ?? ''
                            if (gameCategorySlotImage.length > 0) {
                                imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, gameCategorySlotImage, companyInfo.company._id)
                                isDefaultImage = false
                            }

                            break
                        case 3:
                            imageUrl = `${process.env.PUBLIC_URL}/images/game/4d.png`

                            let gameCategoryLotteryImage = companyInfo.company.gameCategoryLotteryImage ?? ''
                            if (gameCategoryLotteryImage.length > 0) {
                                imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, gameCategoryLotteryImage, companyInfo.company._id)
                                isDefaultImage = false
                            }

                            break
                        case 4:
                            imageUrl = `${process.env.PUBLIC_URL}/images/game/sportbook.png`

                            let gameCategorySportImage = companyInfo.company.gameCategorySportImage ?? ''
                            if (gameCategorySportImage.length > 0) {
                                imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, gameCategorySportImage, companyInfo.company._id)
                                isDefaultImage = false
                            }

                            break
                        case 5:
                            imageUrl = `${process.env.PUBLIC_URL}/images/game/others.png`

                            let gameCategoryOthersImage = companyInfo.company.gameCategoryOthersImage ?? ''
                            if (gameCategoryOthersImage.length > 0) {
                                imageUrl = Utils.shared.getCompanyImagesLink(companyInfo, gameCategoryOthersImage, companyInfo.company._id)
                                isDefaultImage = false
                            }

                            break
                        default:
                            break
                    }
                }

                return {
                    image: imageUrl,
                    key: key,
                    label: value,
                    isDefaultImage: isDefaultImage
                }
            })

            const getGameCategoryButtons = () => {
                const getImageButton = ({ image, key, index, isSelected }) => {
                    return (
                        <div style={{ borderBottom: isSelected ? '3px solid yellow' : '2px solid white' }}
                            key={`gameCategoryContainer${index}`}>
                            <div style={{ margin: imageMargin, marginBottom: marginBottom }}>
                                <Button style={{ backgroundColor: 'transparent', padding: 0, margin: 0 }} onClick={() => onSelected(key)}>
                                    <img src={image} key={`gameCategory${index}`} alt='gameCategory' loading="lazy" width={imageSize} height={imageSize} draggable={false}>
                                    </img>
                                </Button>
                            </div>
                        </div >
                    )
                }

                if (template.design === Constants.templateDesignModern) {
                    return (
                        <>
                            <Box sx={{ width: '16px' }} />
                            {
                                gameCategoryImages.map((e, index) => {
                                    let isSelected = selectedKey === e.key
                                    let label = e.label ?? ''
                                    let isDefaultImage = e.isDefaultImage ?? true
                                    var image = isDefaultImage ? `${process.env.PUBLIC_URL}/images/game/others.png` : e?.image

                                    if (isDefaultImage) {
                                        //Check if have title
                                        if (label.length > 0) {
                                            return (
                                                <ButtonSelectable title={t(label)} value={e.key} isSelected={isSelected} onSelected={onSelected} />
                                            )
                                        } else {
                                            return getImageButton({
                                                image: image,
                                                key: e.key,
                                                index: index,
                                                isSelected: isSelected
                                            })
                                        }
                                    } else {
                                        return getImageButton({
                                            image: image,
                                            key: e.key,
                                            index: index,
                                            isSelected: isSelected
                                        })
                                    }
                                })
                            }
                            <Box sx={{ width: '16px' }} />
                        </>
                    )
                } else {
                    return (
                        <Tabs variant='scrollable' scrollButtons={'auto'} value={false} onChange={(e, value) => {
                            onSelected(value)
                        }} sx={{
                            '.MuiTabs-scrollButtons': {
                                color: 'white',
                                backgroundColor: 'black'
                            },
                            '.MuiTabs-scrollButtons.Mui-disabled': {
                                opacity: 0.3
                            },
                        }}>
                            {
                                gameCategoryImages.map((e, index) => {
                                    let isSelected = selectedKey === e.key

                                    return <Tab sx={{
                                        padding: 0
                                    }} icon={
                                        getImageButton({
                                            image: e.image,
                                            key: e.key,
                                            index: index,
                                            isSelected: isSelected
                                        })
                                    } value={e.key} />
                                })
                            }
                        </Tabs>
                    )
                }
            }

            const getCategoryListJusticyContent = () => {
                if (template.design === Constants.templateDesignModern) {
                    if (screenType.isMobile || screenType.isTablet) {
                        return 'left'
                    } else {
                        return 'center'
                    }
                }

                return null
            }

            return (
                <Stack key='gameCategoryList' className="gameCategoryList" sx={{
                    msOverflowStyle: '-ms-autohiding-scrollbar',
                    scrollbarWidth: 'none',
                    width: '100%',
                    overflowX: (template.design === Constants.templateDesignModern) ? 'scroll' : 'auto',
                    paddingBottom: (template.design === Constants.templateDesignModern) ? '8px' : null,
                }} direction={'row'} spacing={template.design === Constants.templateDesignModern ? '16px' : null} justifyContent={getCategoryListJusticyContent()} >
                    {getGameCategoryButtons()}
                </Stack>
            )
        } else {
            return null
        }
    }
}

export default GameCategoryView;