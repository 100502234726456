import React, { useContext, useState, useEffect } from "react"
import { AlertDialogContext, CompanyInfoContext, ForgotPwdPopupContext, SnackBarContext, UserInfoContext } from '../../App'
import Constants from '../../constants'
import InputTextField from '../../components/textfield/inputTextField'
import BaseButton from "../../components/button/button"
import { useNavigate } from "react-router-dom"
import FeathersClient, { request } from "../../feathers/feathersClient"
import Utils from "../../utils/utils"
import VerificationView from '../../components/auth/verificationView'
import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ChevronLeft, Close } from '@mui/icons-material'
import { useTranslation } from "react-i18next"
import { useTemplateVariables } from "../../utils/templateUtils"
import ResetPwdSideImage from '../../images/svg/resetPwdSideImage.svg'
import useScreenType from "../../hooks/useScreenType"

export default function ForgotPassword() {
    const navigate = useNavigate()
    const template = useTemplateVariables()
    const screenType = useScreenType()

    // Context
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const { setAlertDialog } = useContext(AlertDialogContext)
    const { setOpenForgotPwd } = useContext(ForgotPwdPopupContext)
    const { setSnackBar } = useContext(SnackBarContext)
    const { t } = useTranslation()
    const client = request({
        setAlertDialog: setAlertDialog,
        setCompanyInfo: setCompanyInfo,
        setUserInfo: setUserInfo,
        navigate: navigate
    })

    // States
    const [values, setValues] = useState({
        contactNumber: '',
        contactNumberPhoneCode: '',
        contactNumberError: '',
        newPassword: '',
        newPasswordError: '',
        confirmPassword: '',
        confirmPasswordError: ''
    })

    const [isOTPMode, setIsOTPMode] = useState(false)
    const [isVerifiedContactNumber, setIsVerifiedContactNumber] = useState(false)
    const [otpValues, setOTPValues] = useState({
        verificationCode: '',
        userId: '',
        contactNumber: '',
        smsResendRemaining: 3,
    })

    //Styles
    const styles = {
        modern: {
            desktop: {
                backgroundColor: template.mainFontColor,
                width: 'calc(60% - 32px)',
                height: 'calc(100% - 32px)',
                position: 'absolute',
                right: 0,
                padding: '16px',
                borderRadius: '32px',
                overflow: 'hidden'
            },
            mobile: {
                backgroundColor: template.mainFontColor,
                padding: '16px',
                overflow: 'hidden',
                height: '100%'
            }
        }
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo.company.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //API Methods
    function performTriggerOTP() {
        if (values.contactNumber.length === 0) {
            setValues(values => ({ ...values, contactNumberError: `${t('AUTH_MOBILE_NUMBER')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        client.create('authManagement', {
            action: 'sendResetPwd',
            value: {
                contactNumber: `${values.contactNumberPhoneCode}${values.contactNumber}`,
                company: companyInfo.company._id
            }
        }, null, (response) => {
            setSnackBar(snackBar => ({
                ...snackBar,
                title: 'Code sent successfully',
                isOpen: true
            }))

            setOTPValues(otpValues => ({
                ...otpValues,
                verificationCode: '',
                userId: response._id,
                contactNumber: `${values.contactNumberPhoneCode}${values.contactNumber}`,
                smsResendRemaining: response?.smsResendLimit ?? 3
            }))
            setIsOTPMode(true)
        })
    }

    function performResetPassword() {
        if (values.newPassword.length === 0) {
            setValues(values => ({ ...values, newPasswordError: `${t('AUTH_NEW_PWD')} ${t('GLOBAL_IS_MANDATORY')}` }))
            return
        }

        if (values.newPassword !== values.confirmPassword) {
            setValues(values => ({ ...values, confirmPasswordError: t('AUTH_PASSWORD_NOT_MATCH') }))
            return
        }

        client.create('authManagement', {
            action: 'resetPwdShort',
            value: {
                user: {
                    contactNumber: `${values.contactNumberPhoneCode}${values.contactNumber}`,
                    company: companyInfo?.company?._id
                },
                token: otpValues.verificationCode,
                password: values.newPassword
            },
        }, null, (response) => {
            //Authenticated, store user object
            const user = response.user
            setUserInfo(user)

            //Store token
            window.localStorage.setItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`, response.accessToken)

            //Authenticate feathers
            client.reauthenticate(() => {
                //Navigate to home page
                Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                    setOpenForgotPwd(false)
                    navigate('/', { replace: true })
                }, true)
            }
            )
        })
    }

    //Component Methods
    const getVerifyContactNumberView = () => {
        if (template.design === Constants.templateDesignModern) {
            return (
                <>
                    <Stack>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '26px',
                            color: template.navigationFontColor
                        }}>
                            {t('AUTH_FORGOT_PWD')}
                        </Typography>
                        <Divider sx={{
                            height: '2px',
                            backgroundColor: 'white',
                            width: '40%'
                        }} />
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '22px',
                            color: 'white'
                        }}>
                            {'Enter your registered contact number to receive instructions on how to reset your password'}
                        </Typography>
                    </Stack>
                    <InputTextField
                        value={values.contactNumber}
                        type='tel'
                        placeholder="e.g. 126668888"
                        titleColor={color}
                        showError={values.contactNumberError.length > 0}
                        errorText={values.contactNumberError}
                        disabled={isOTPMode}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, contactNumber: value }))
                            setValues(values => ({ ...values, contactNumberError: '' }))
                        }}
                        onSubmit={() => {
                            performTriggerOTP()
                        }}
                        onPhoneCodeChanged={(value) => {
                            setValues(values => ({ ...values, contactNumberPhoneCode: value }))
                            setValues(values => ({ ...values, contactNumberError: '' }))
                        }} />
                    {isOTPMode ? null : (
                        <>
                            <BaseButton title={t('AUTH_SUBMIT')} color={template.navigationFontColor} textColor={template.mainFontColor} enableGradient={false} borderRadius="16px" fullWidth={true} onTap={(event) => {
                                //Perform login
                                performTriggerOTP()
                            }} />
                            <Stack direction={'row'} spacing='4px'>
                                <Button sx={{
                                    padding: '0px',
                                    textTransform: 'none'
                                }} onClick={() => {
                                    setOpenForgotPwd(false)
                                    navigate('/chat')
                                }}>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '22px',
                                        color: 'white'
                                    }}>
                                        {t('AUTH_NEED_HELP_CLICK_HERE')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </>
                    )}
                </>
            )
        } else {
            return (
                <div>
                    <InputTextField
                        title={t('AUTH_MOBILE_NUMBER')}
                        value={values.contactNumber}
                        type='tel'
                        placeholder="e.g. 126668888"
                        helperText={t('AUTH_MOBILE_NUMBER_HELPER')}
                        titleColor={color}
                        showError={values.contactNumberError.length > 0}
                        errorText={values.contactNumberError}
                        disabled={isOTPMode}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, contactNumber: value }))
                            setValues(values => ({ ...values, contactNumberError: '' }))
                        }}
                        onSubmit={() => {
                            performTriggerOTP()
                        }}
                        onPhoneCodeChanged={(value) => {
                            setValues(values => ({ ...values, contactNumberPhoneCode: value }))
                            setValues(values => ({ ...values, contactNumberError: '' }))
                        }} />
                    <br />
                    {isOTPMode ? null : (
                        <BaseButton title={t('AUTH_SUBMIT')} onTap={(event) => {
                            //Perform login
                            performTriggerOTP()
                        }} />
                    )}
                </div>
            )
        }
    }

    const getResetPasswordView = () => {
        if (template.design === Constants.templateDesignModern) {
            return (
                <Stack spacing='16px'>
                    <Stack>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '26px',
                            color: template.navigationFontColor
                        }}>
                            {t('AUTH_FORGOT_PWD')}
                        </Typography>
                        <Divider sx={{
                            height: '2px',
                            backgroundColor: 'white',
                            width: '40%'
                        }} />
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '22px',
                            color: 'white'
                        }}>
                            {t('FORGOT_PWD_DESC')}
                        </Typography>
                    </Stack>
                    <InputTextField
                        title={t('AUTH_NEW_PWD')}
                        value={values.newPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_NEW_PWD')}`}
                        helperText={t('AUTH_NEW_PWD_HELPER')}
                        titleColor={color}
                        showError={values.newPasswordError.length > 0}
                        errorText={values.newPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, newPassword: value }))
                            setValues(values => ({ ...values, newPasswordError: '' }))
                        }}
                        onSubmit={() => {
                            performResetPassword()
                        }} />
                    <InputTextField
                        title={t('AUTH_CONFIRM_PWD')}
                        value={values.confirmPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_CONFIRM_PWD')}`}
                        helperText={t('AUTH_CONFIRM_PWD_HELPER')}
                        titleColor={color}
                        showError={values.confirmPasswordError.length > 0}
                        errorText={values.confirmPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, confirmPassword: value }))
                            setValues(values => ({ ...values, confirmPasswordError: '' }))
                        }}
                        onSubmit={() => {
                            performResetPassword()
                        }} />
                    <BaseButton title={t('AUTH_SUBMIT')} color={template.navigationFontColor} textColor={template.mainFontColor} enableGradient={false} borderRadius="16px" fullWidth={true} onTap={(event) => {
                        performResetPassword()
                    }} />
                </Stack>
            )
        } else {
            return (
                <div>
                    <InputTextField
                        title={t('AUTH_NEW_PWD')}
                        value={values.newPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_NEW_PWD')}`}
                        helperText={t('AUTH_NEW_PWD_HELPER')}
                        titleColor={color}
                        showError={values.newPasswordError.length > 0}
                        errorText={values.newPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, newPassword: value }))
                            setValues(values => ({ ...values, newPasswordError: '' }))
                        }}
                        onSubmit={() => {
                            performResetPassword()
                        }} />
                    <br />
                    <InputTextField
                        title={t('AUTH_CONFIRM_PWD')}
                        value={values.confirmPassword}
                        type='password'
                        placeholder={`${t('GLOBAL_ENTER')} ${t('AUTH_CONFIRM_PWD')}`}
                        helperText={t('AUTH_CONFIRM_PWD_HELPER')}
                        titleColor={color}
                        showError={values.confirmPasswordError.length > 0}
                        errorText={values.confirmPasswordError}
                        onTextChanged={(value) => {
                            setValues(values => ({ ...values, confirmPassword: value }))
                            setValues(values => ({ ...values, confirmPasswordError: '' }))
                        }}
                        onSubmit={() => {
                            performResetPassword()
                        }} />
                    <br />
                    <BaseButton title={t('AUTH_SUBMIT')} onTap={(event) => {
                        //Perform login
                        performResetPassword()
                    }} />
                    <br />
                    <br />
                </div>
            )
        }
    }

    const getOTpModeView = () => {
        return (
            <VerificationView userId={otpValues.userId} contactNumber={otpValues.contactNumber} remainingResend={otpValues?.smsResendRemaining} type='forgotPwd' onVerificationCodeChanged={(code) => {
                setOTPValues(otpValues => ({
                    ...otpValues,
                    verificationCode: code
                }))
            }} onVerificationSucceeded={(auth) => {
                setIsVerifiedContactNumber(true)
                setIsOTPMode(false)
            }} />
        )
    }

    if (template.design === Constants.templateDesignModern) {
        return (
            <Box sx={{
                backgroundColor: 'white',
                height: '100%',
                width: '100%',
                position: 'relative'
            }}>
                <Stack sx={screenType.isMobile ? styles.modern.mobile : styles.modern.desktop} alignItems={'start'} justifyContent={'center'}>
                    <Stack sx={{
                        padding: '32px',
                        width: 'calc(100% - 64px)'
                    }} spacing='32px'>
                        {
                            isVerifiedContactNumber ? getResetPasswordView() : getVerifyContactNumberView()
                        }
                        {
                            isOTPMode ? getOTpModeView() : null
                        }
                    </Stack>

                    <IconButton onClick={() => {
                        setOpenForgotPwd(false)
                    }} size="small" sx={{
                        backgroundColor: template.mainFontColor,
                        position: 'absolute',
                        top: 16,
                        right: 16
                    }}>
                        <Close htmlColor="white" fontSize="small" />
                    </IconButton>
                </Stack>
                {
                    screenType.isMobile ? null : (
                        <Stack direction={'row'} sx={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            alignItems: 'end'
                        }}>
                            <img src={ResetPwdSideImage} height={'100%'} width={'95%'} />
                        </Stack>
                    )
                }
            </Box>
        )
    } else {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', color: color }}>
                    <IconButton
                        size="large"
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <ChevronLeft htmlColor={color} />
                    </IconButton>
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
                        {t('AUTH_FORGOT_PWD')}
                    </Typography>
                </Box>
                <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white' }}></div>
                <div style={{ margin: '20px' }}>
                    {isVerifiedContactNumber ? getResetPasswordView() : getVerifyContactNumberView()}
                    {isOTPMode ? getOTpModeView() : null}
                    <br />
                    <br />
                </div>
            </div>
        )
    }
}