import { ToggleButtonGroup, ToggleButton, Typography, Button, Grid } from "@mui/material"
import { useState } from "react"
import Constants from '../../constants'
import { useTemplateVariables } from "../../utils/templateUtils"

export default function BaseToggleButton({ items = [], defaultValue = null, onValueChanged, useKeyValue = false }) {
    const template = useTemplateVariables()

    //States
    const [selectedIndex, setSelectedIndex] = useState(defaultValue)

    if (template.design === Constants.templateDesignModern) {
        return (
            <Grid container columnGap={'8px'} rowGap={'8px'}>
                {
                    items.map((e, index) => {
                        let isSelected = useKeyValue ? (defaultValue === e?.key) : (selectedIndex === index || defaultValue === index)
                        let title = useKeyValue ? e?.value : e

                        return (
                            <Grid item>
                                <Button sx={{
                                    padding: '12px 16px',
                                    borderRadius: '16px',
                                    border: isSelected ? `1px solid ${template.mainFontColor}` : null,
                                    color: isSelected ? template.mainFontColor : '#828282',
                                    boxShadow: isSelected ? '0px 1px 1px 0px rgba(23, 53, 89, 0.16)' : null,
                                    backgroundColor: isSelected ? 'white' : '#F4F6F8',
                                    textTransform: 'none',
                                    overflow: 'clip'
                                }} onClick={(event) => {
                                    setSelectedIndex(index)

                                    if (useKeyValue) {
                                        onValueChanged(event, index, e?.key)
                                    } else {
                                        onValueChanged(event, index)
                                    }
                                }}>
                                    <Typography sx={{
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        lineHeight: '16px'
                                    }}>
                                        {title}
                                    </Typography>
                                </Button>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    } else {
        return (
            <ToggleButtonGroup
                value={selectedIndex}
                exclusive
                sx={{ color: template.otherButtonFontColor, backgroundColor: template.otherButtonColor }}
                onChange={(event, index) => {
                    setSelectedIndex(index)
                    onValueChanged(event, index)
                }}
            >
                {items.map((item, index) => {
                    return (
                        <ToggleButton key={`toggle${index}`} value={index}>{item}</ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        )
    }
}