import React, { useContext, useEffect, useState } from "react"
import { ClearBalancePopupContext, CompanyInfoContext, UserInfoContext } from '../../App'
import Constants from '../../constants'
import Utils from "../../utils/utils"
import { Stack, IconButton, Tooltip, Box, CircularProgress, Typography, Button, Collapse } from '@mui/material'
import { ExpandLess, ExpandMore, Refresh } from '@mui/icons-material'
import { request } from "../../feathers/feathersClient"
import { useTimer } from 'react-timer-hook'
import { useUserInfo } from "../../hooks/useUserInfo"
import { useNavigate } from "react-router-dom"
import { useCompanyInfo } from "../../hooks/useCompanyInfo"
import { useTemplateVariables } from "../../utils/templateUtils"
import AmountView from "../views/amountView"
import UserSummaryDetailsView from "../views/userSummaryDetailsView"
import TurnoverDetailsView from "../views/turnoverDetailsView"
import RolloverDetailsView from "../views/rollOverDetailsView"
import MinWithdrawalDetailsView from "../views/minWithdrawalDetailsView"
import WithdrawalFeeDetailsView from "../views/withdrawalFeeDetailsView"
import MaxWithdrawalDetailsView from "../views/maxWithdrawalDetailsView"
import DepositButton from "../button/depositButton"
import WithdrawalButton from "../button/withdrawalButton"
import CircularProgressWithLabel from "../progressIndicator/circularProgressWithLabel"
import { useTranslation } from "react-i18next"

export default function BalanceView({ invertColor = false, primaryColor = null, secondaryColor = null }) {
    let navigate = useNavigate()

    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext)
    const { setUserInfo } = useContext(UserInfoContext)
    const user = useUserInfo()
    const company = useCompanyInfo()
    const client = request({
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })
    const template = useTemplateVariables()
    const { t } = useTranslation()
    const { setOpenClearBalance } = useContext(ClearBalancePopupContext)

    //States
    const [accounts, setAccounts] = useState([])
    const [banks, setBanks] = useState(null)
    const [showRefreshButton, setShowRefreshButton] = useState(true)
    const [isViewMore, setIsViewMore] = useState(false)

    let scheduleSeconds = 30
    const countDown = new Date()
    countDown.setSeconds(countDown.getSeconds() + scheduleSeconds)
    const { restart, seconds, isRunning } = useTimer({ autoStart: false, expiryTimestamp: countDown })

    //API METHODS
    const callSchedulerPlayerBalance = () => {
        if (company && user) {
            client.create('scheduler-player-balance', {
                company: company._id,
                user: user._id,
            }, null, null, null, false, () => {
                //Restart scheduler
                restartTimer()
            })
        }
    }
    const restartTimer = () => {
        const newCountDown = new Date()
        newCountDown.setSeconds(newCountDown.getSeconds() + scheduleSeconds)
        restart(newCountDown)
    }

    //COMPONENT METHODS
    const getRefreshButton = () => {
        return showRefreshButton ? (
            <Tooltip title='Refresh balance'>
                <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => {
                    //Refresh balance
                    client.create('get-player-games-balance', {
                        company: company?._id,
                        user: user._id,
                        isBOWithdraw: false
                    }, null, (response) => {

                    }, null, true, () => {
                        //Reset timer here
                        setShowRefreshButton(false)
                        restartTimer()
                    })
                }}>
                    <Refresh htmlColor={primaryColor ?? template.mainFontColor} fontSize='small' />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title='Please wait for next available refresh'>
                <CircularProgressWithLabel value={seconds} max={scheduleSeconds} />
            </Tooltip>
        )
    }
    const getBalanceTitle = () => {
        return (
            <Stack direction={'row'} alignItems={'center'} spacing={'5px'}>
                <Typography sx={{
                    fontSize: '10px',
                    fontWeight: '400',
                    textTransform: 'uppercase'
                }}>
                    {t('BALANCE')}
                </Typography>
                {getRefreshButton()}
            </Stack>
        )
    }

    useEffect(() => {
        if (seconds === 0 && !isRunning) {
            //Enable refresh button
            setShowRefreshButton(true)
        }
    }, [seconds, isRunning])
    useEffect(() => {
        if (user && companyInfo) {
            Utils.shared.getPlayerWithdrawalAccounts(companyInfo, user, null, (banks, withdrawalAccounts) => {
                setAccounts(withdrawalAccounts)
                setBanks(banks)
            }, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Only show after login
    if (user) {
        //From userInfo
        let fullName = user?.fullName
        let contactNumber = user?.contactNumber
        let greetingDisplay = fullName ?? contactNumber ?? ''

        let playerVirtualCredits = user?.playerVirtualCredits ?? 0

        //From company
        let currencyCode = company?.currencyCode ?? ''

        const getGreetingDisplay = () => {
            let withdrawalBankAccountNumber = accounts?.length > 0 ? accounts[0]?.withdrawalBankAccountNumber : ''
            let withdrawalBank = accounts?.length > 0 ? banks?.find((e) => e.key === accounts[0].withdrawalBank)?.value ?? '' : ''

            return (
                <Stack>
                    <Box sx={{ color: template.mainFontColor }}>
                        Welcome, <span style={{ fontSize: 16, color: template.nameFontColor }}><b>{greetingDisplay}</b></span>
                    </Box>
                    {
                        accounts?.length > 0 ? (
                            <Box sx={{ color: template.mainFontColor, fontSize: 13 }}>
                                {`${withdrawalBank} (${withdrawalBankAccountNumber})`}
                            </Box>
                        ) : null
                    }
                </Stack>
            )
        }

        if (template.design === Constants.templateDesignModern) {
            return (
                <Stack sx={{
                    backgroundColor: invertColor ? template.mainFontColor : 'white'
                }}>
                    <Stack direction={'row'} sx={{
                        padding: '12px'
                    }} >
                        <AmountView title={getBalanceTitle()} titleFontColor={invertColor ? `${Utils.shared.brighten(template.navigationFontColor, 4)}` : null} value={Utils.shared.getAmountString(playerVirtualCredits, '', company)} valueFontColor={primaryColor ? primaryColor : invertColor ? template.navigationFontColor : template.mainFontColor} />
                        <Box sx={{ width: '8px' }} />
                        <DepositButton invertColor={invertColor} />
                        <Box sx={{ width: '8px' }} />
                        <WithdrawalButton invertColor={invertColor} />
                    </Stack>
                    <Collapse in={isViewMore}>
                        <Stack>
                            <Box sx={{
                                padding: '16px 0px',
                            }}>
                                {Utils.shared.getClearBalanceButton({
                                    companyInfo: companyInfo,
                                    userInfo: user,
                                    template: template,
                                    setOpenClearBalance: setOpenClearBalance,
                                    translator: t
                                })}
                            </Box>
                            <UserSummaryDetailsView />
                        </Stack>
                    </Collapse>
                    <Button sx={{
                        backgroundColor: secondaryColor ? secondaryColor : invertColor ? 'white' : template.navigationFontColor,
                        width: '100%',
                        color: primaryColor ?? template.mainFontColor,
                        padding: '0px',
                        borderRadius: '0px',
                        ":hover": {
                            backgroundColor: secondaryColor ? secondaryColor : invertColor ? 'rgba(255, 255, 255, 0.95)' : Utils.shared.shadeColor(template.navigationFontColor, 5)
                        }
                    }} onClick={() => {
                        setIsViewMore(!isViewMore)
                    }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            <div style={{
                                fontSize: '8px',
                                fontWeight: '600',
                                lineHeight: '120%',
                                textTransform: 'uppercase'
                            }}>{isViewMore ? 'SHOW LESS' : 'VIEW MORE'}</div>
                            {
                                isViewMore ? (
                                    <ExpandLess fontSize='small' />
                                ) : (
                                    <ExpandMore fontSize='small' />
                                )
                            }
                        </Stack>
                    </Button>
                </Stack>
            )
        } else {
            return (
                <Stack direction='column' spacing='5px' sx={{ color: template.mainFontColor }}>
                    {getGreetingDisplay()}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <b>{`${t('BALANCE')} : ${Utils.shared.getAmountString(playerVirtualCredits, currencyCode, company)}`}</b>
                        <div style={{ width: '10px' }} />
                        {
                            getRefreshButton()
                        }
                    </div>
                    <TurnoverDetailsView />
                    <RolloverDetailsView />
                    <MinWithdrawalDetailsView />
                    <WithdrawalFeeDetailsView />
                    <MaxWithdrawalDetailsView />
                    {Utils.shared.getClearBalanceButton({
                        companyInfo: companyInfo,
                        userInfo: user,
                        template: template,
                        setOpenClearBalance: setOpenClearBalance,
                        translator: t
                    })}
                    <div style={{ height: '10px' }} />
                </Stack>
            )
        }
    } else {
        return null
    }
}